import extend from 'extend';
import _ from 'underscore';

export default {
    props: {
        value_001: {
            type: String,
            required: false,
            default: `-1`,
        },
    },
    data() {
        return {
            value_001_for_sync: `-1`,

            entity_list: [
                {value: `0`, text: `全部`, active: false},
                {value: `1`, text: `男人`, active: false},
                {value: `2`, text: `女人`, active: false},
            ],
        };
    },
    watch: {
        value_001(pNewValue, pOldValue) {
            const __this = this;
            __this.value_001_for_sync = pNewValue;
        },
        value_001_for_sync(pNewValue, pOldValue) {
            const __this = this;
            __this.$emit(`update:value_001`, pNewValue);

            const __init_entity_list = __this.__init_entity_list;
            __init_entity_list();
        },
    },
    methods: {
        __btn_entity_click_event_handler(pEntity) {
            const __this = this;

            _.each(__this.entity_list, function (m) {
                m.active = false;
            });
            pEntity.active = true;

            __this.value_001_for_sync = pEntity.value;
        },

        __init_entity_list() {
            const __this = this;

            const entity_list = __this.entity_list;

            _.each(entity_list, function (m) {
                m.active = false;
            });

            const query = _.chain(entity_list)
                .find(function (m) {
                    return m.value === __this.value_001;
                })
                .value()
            ;
            if (query) {
                query.active = true;
                __this.value_001_for_sync = query.value;
                return;
            }

            //v-model的值非法时，选取第一个值
            if (entity_list.length > 0) {
                const first_entity = entity_list[0];
                __this.value_001_for_sync = first_entity.value;
                first_entity.active = true;
            }
        },
    },
    created() {
        const __this = this;
        const __init_entity_list = __this.__init_entity_list;
        __init_entity_list();
    },
}
