import SyncTest from '@/component/sync-test/index.vue'

export default {
    components: {
        SyncTest,
    },
    data() {
        return {
            // value_SyncTest: ``,
            value_SyncTest: `1`,
        };
    },
    methods: {
        __btn_get_value_click_event_handler() {
            const __this = this;
            console.log(__this.value_SyncTest);
        },

        __btn_set_value_click_event_handler() {
            const __this = this;
            __this.value_SyncTest = `2`;
        },

        __btn_reset_value_click_event_handler() {
            const __this = this;
            __this.value_SyncTest = ``;
        },
    },
    created() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
